var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "padding-top": "1rem" } }, [
    _c(
      "div",
      { staticClass: "reports_filter px-5 mb-8" },
      [
        _c("v-form", { ref: "form", staticClass: "d-flex align-center mb-5" }, [
          !_vm.isLoading
            ? _c("span", [
                _c("small", [_vm._v("Jornada: ")]),
                !_vm.isLoading
                  ? _c("strong", [
                      _vm._v(
                        _vm._s(_vm.currentJourney?.journey?.name || "") + " "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
        _c("z-filter", {
          attrs: {
            "interval-filters-list-prop": _vm.intervalFilters,
            "tempaltes-list-prop": _vm.templates,
            "filter-options-prop": _vm.itemsFilterList,
          },
          on: {
            "chip-created": _vm.onUpdateChipList,
            "remove-all-chips": _vm.onRemoveAllChips,
            "remove-single-chip": _vm.onUpdateChipList,
            "update-chip": _vm.onUpdateChipList,
            "remove-child-chip": _vm.onUpdateChipList,
          },
        }),
      ],
      1
    ),
    !_vm.isLoading
      ? _c("div", { staticClass: "wrapper__card mx-5" }, [
          _c(
            "div",
            { staticClass: "wrapper__card__body" },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "px-3 mb-3 d-flex align-center justify-space-between",
                },
                [
                  _c(
                    "div",
                    { staticClass: "wrapper__card__body__header" },
                    _vm._l(_vm.metricsTypes, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.id + item.type,
                          staticClass: "metric-type mb-2",
                        },
                        [
                          _c("div", {
                            staticClass: "dot",
                            style: { background: item.color },
                          }),
                          _c("small", { style: { color: item.color } }, [
                            _vm._v(_vm._s(item.type)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    [
                      _c("z-btn", {
                        staticClass: "mr-2",
                        attrs: {
                          rounded: "",
                          primary: "",
                          text: "Extração via api",
                          icon: "$upload_file",
                          small: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push(
                              `/dataExtraction/${_vm.$route.params.id}`
                            )
                          },
                        },
                      }),
                      _c("z-btn", {
                        attrs: {
                          rounded: "",
                          primary: "",
                          text: "Registro de falhas de envio",
                          icon: "$report_gmailerrorred_rounded",
                          small: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push(
                              `/reports/journey-reports-fails-details/${_vm.$route.params.id}`
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-row",
                { staticStyle: { width: "100%" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h2", [_vm._v("Metricas Gerais")]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$groups",
                          "main-color-prop": "#0273c3",
                          "border-color-prop": "#ffce4d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" AUDIÊNCIAS ")]
                              },
                              proxy: true,
                            },
                            _vm.loadingAudienceData
                              ? {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("z-circular-progress", {
                                        attrs: {
                                          value: "10",
                                          size: "40",
                                          width: "5",
                                          rotate: "",
                                          indeterminate: true,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.audienceUniqueIdsCounter
                                            ) || 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$send",
                          "main-color-prop": "#239465",
                          "border-color-prop": "#ffce4d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" TOTAL DE ENVIOS ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          +_vm.totalEmailSent +
                                            +_vm.totalSMSSent
                                        ) || 0
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          997344716
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$unsubscribe",
                          "main-color-prop": "#239465",
                          "border-color-prop": "#ffce4d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" OPTOUTS REALIZADOS ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formateNumberToLocal(
                                        _vm.totalOptOuts
                                      ) || 0
                                    ) +
                                      " (" +
                                      _vm._s(
                                        +_vm.totalEmailSent !== 0
                                          ? (
                                              (+_vm.totalOptOuts /
                                                +_vm.totalEmailSent) *
                                              100
                                            ).toFixed(2)
                                          : 0
                                      ) +
                                      "%)"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2467642466
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticStyle: { width: "100%" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h2", [_vm._v("Metricas SMS")]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$send",
                          "main-color-prop": "#ffc220",
                          "border-color-prop": "#30895e",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" TELEFONES OPT-OUT ")]
                              },
                              proxy: true,
                            },
                            _vm.loadingOptoutData
                              ? {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("z-circular-progress", {
                                        attrs: {
                                          value: "10",
                                          size: "40",
                                          width: "5",
                                          rotate: "",
                                          indeterminate: true,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.phonesInOptOut
                                            ) || 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$send",
                          "main-color-prop": "#ffc220",
                          "border-color-prop": "#30895e",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" TELEFONES VÁLIDOS ")]
                              },
                              proxy: true,
                            },
                            _vm.loadingOptoutData
                              ? {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("z-circular-progress", {
                                        attrs: {
                                          value: "10",
                                          size: "40",
                                          width: "5",
                                          rotate: "",
                                          indeterminate: true,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.audienceValidPhones
                                            ) || 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$send",
                          "main-color-prop": "#ffc220",
                          "border-color-prop": "#30895e",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" SMS ENVIADOS ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formateNumberToLocal(
                                        _vm.totalSMSSent
                                      ) || 0
                                    )
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1896441134
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$arrow_back",
                          "main-color-prop": "#ff8f28",
                          "border-color-prop": "#30895e",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" CLIQUES SMS ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          _vm.totalSMSClicks
                                        ) || 0
                                      ) +
                                      " (" +
                                      _vm._s(
                                        +_vm.totalSMSSent !== 0
                                          ? (
                                              (+_vm.totalSMSClicks /
                                                +_vm.totalSMSSent) *
                                              100
                                            ).toFixed(2)
                                          : 0
                                      ) +
                                      "%)"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4044585910
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticStyle: { width: "100%" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h2", [_vm._v("Metricas Email")]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$unsubscribe",
                          "main-color-prop": "#674099",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" EMAILS OPT-OUT ")]
                              },
                              proxy: true,
                            },
                            _vm.loadingOptoutData
                              ? {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("z-circular-progress", {
                                        attrs: {
                                          value: "10",
                                          size: "40",
                                          width: "5",
                                          rotate: "",
                                          indeterminate: true,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.emailsInOptOut
                                            ) || 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$email",
                          "main-color-prop": "#674099",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" EMAILS VÁLIDOS ")]
                              },
                              proxy: true,
                            },
                            _vm.loadingOptoutData
                              ? {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _c("z-circular-progress", {
                                        attrs: {
                                          value: "10",
                                          size: "40",
                                          width: "5",
                                          rotate: "",
                                          indeterminate: true,
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                }
                              : {
                                  key: "value",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formateNumberToLocal(
                                              _vm.audienceValidEmails
                                            ) || 0
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$send",
                          "main-color-prop": "#674099",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" EMAILS ENVIADOS ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          _vm.totalEmailSent
                                        ) || 0
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3788892541
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$send",
                          "main-color-prop": "#674099",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" EMAILS ENTREGUES ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          _vm.totalEmailDelivery
                                        ) || 0
                                      ) +
                                      " (" +
                                      _vm._s(
                                        +_vm.totalEmailSent !== 0
                                          ? (
                                              (+_vm.totalEmailDelivery /
                                                +_vm.totalEmailSent) *
                                              100
                                            ).toFixed(2)
                                          : 0
                                      ) +
                                      "%)"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3226344092
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "mdi-email-open-outline",
                          "main-color-prop": "#239465",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" ABERTURAS EMAIL ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          _vm.totalEmailOpens
                                        ) || 0
                                      ) +
                                      " (" +
                                      _vm._s(
                                        +_vm.totalEmailSent !== 0
                                          ? (
                                              (+_vm.totalEmailOpens /
                                                +_vm.totalEmailSent) *
                                              100
                                            ).toFixed(2)
                                          : 0
                                      ) +
                                      "%)"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3381867094
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$arrow_back",
                          "main-color-prop": "#ff8f28",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" CLIQUES EMAIL ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formateNumberToLocal(
                                        _vm.totalEmailClicks
                                      ) || 0
                                    ) +
                                      " (" +
                                      _vm._s(
                                        +_vm.totalEmailSent !== 0
                                          ? (
                                              (+_vm.totalEmailClicks /
                                                +_vm.totalEmailSent) *
                                              100
                                            ).toFixed(2)
                                          : 0
                                      ) +
                                      "%)"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3677012028
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "mdi-email-sync-outline",
                          "main-color-prop": "#ffc220",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" BOUNCES EMAIL ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formateNumberToLocal(
                                        _vm.totalEmailBounces
                                      ) || 0
                                    ) +
                                      " (" +
                                      _vm._s(
                                        +_vm.totalEmailSent !== 0
                                          ? (
                                              (+_vm.totalEmailBounces /
                                                +_vm.totalEmailSent) *
                                              100
                                            ).toFixed(2)
                                          : 0
                                      ) +
                                      "%)"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2977777227
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "mdi-alert-octagon-outline",
                          "main-color-prop": "#0273c3",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" SPAM EMAIL ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formateNumberToLocal(
                                        _vm.totalEmailSpammed
                                      ) || 0
                                    ) +
                                      " (" +
                                      _vm._s(
                                        +_vm.totalEmailSent !== 0
                                          ? (
                                              (+_vm.totalEmailSpammed /
                                                +_vm.totalEmailSent) *
                                              100
                                            ).toFixed(2)
                                          : 0
                                      ) +
                                      "%)"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3245762919
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$reply",
                          "main-color-prop": "#ffc220",
                          "border-color-prop": "#411e6d",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" RESPOSTAS EMAIL ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formateNumberToLocal(
                                        _vm.totalEmailSpammed
                                      ) || 0
                                    ) +
                                      " (" +
                                      _vm._s(
                                        +_vm.totalEmailSent !== 0
                                          ? (
                                              (+_vm.totalEmailSpammed /
                                                +_vm.totalEmailSent) *
                                              100
                                            ).toFixed(2)
                                          : 0
                                      ) +
                                      "%)"
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2683657638
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticStyle: { width: "100%" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h2", [_vm._v("Metricas Push")]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "mdi-alert-octagon-outline",
                          "main-color-prop": "rgb(105, 188, 203)",
                          "border-color-prop": "#53A9D8",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" TOTAL DE ENVIOS PUSH ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formateNumberToLocal(
                                        _vm.pushInfosCards?.count_total
                                      )
                                    )
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3002097571
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "mdi-email-open-outline",
                          "main-color-prop": "rgb(105, 188, 203)",
                          "border-color-prop": "#53A9D8",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" ABERTURAS PUSH ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          _vm.pushInfosCards?.count_opened
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatePercentToChips(
                                          _vm.pushInfosCards?.count_opened,
                                          _vm.pushInfosCards?.count_total
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          719608186
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "$arrow_back",
                          "main-color-prop": "rgba(73, 203, 140, 1)",
                          "border-color-prop": "#53A9D8",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" CLIQUES PUSH ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          _vm.pushInfosCards?.count_opened
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatePercentToChips(
                                          _vm.pushInfosCards?.count_clicked,
                                          _vm.pushInfosCards?.count_total
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1081111905
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "mdi-email-sync-outline",
                          "main-color-prop": "rgb(100, 45, 108)",
                          "border-color-prop": "#53A9D8",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" BOUNCES PUSH ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          _vm.pushInfosCards?.count_opened
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatePercentToChips(
                                          _vm.pushInfosCards?.count_spam,
                                          _vm.pushInfosCards?.count_total
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1927886614
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("CardReport", {
                        attrs: {
                          icon: "mdi-alert-octagon-outline",
                          "main-color-prop": "rgb(222, 87, 66)",
                          "border-color-prop": "#53A9D8",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [_vm._v(" SPAM PUSH ")]
                              },
                              proxy: true,
                            },
                            {
                              key: "value",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formateNumberToLocal(
                                          _vm.pushInfosCards?.count_opened
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.formatePercentToChips(
                                          _vm.pushInfosCards?.count_spam,
                                          _vm.pushInfosCards?.count_total
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1765720986
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "space-around" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-2 my-2 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("JORNADA")]
                              ),
                              _c(
                                "span",
                                [
                                  _c("small", { staticClass: "mr-2" }, [
                                    _vm._v("Início: "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDate(_vm.journey.created)
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("small", [
                                    _vm._v("Fim: "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          !_vm.journey.cron
                                            ? _vm.formatDate(
                                                _vm.journey.created
                                              )
                                            : _vm.journey.cron.endsAt
                                            ? _vm.formatDate(
                                                _vm.journey.cron.endsAt
                                              )
                                            : "Recorrente"
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm.clickedsNodes.length
                                    ? [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "ml-5",
                                            attrs: { color: "#004C91" },
                                            on: {
                                              click: _vm.resetFilterByJourney,
                                            },
                                          },
                                          [_vm._v("$settings_backup_restore")]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "tree-component" },
                            [
                              _c("TreeComponent", {
                                key: _vm.keyComponent,
                                attrs: {
                                  "database-config-id-prop":
                                    _vm.databaseConfigId,
                                  nodes: _vm.tree,
                                },
                                on: { node_click: _vm.treeNodeClicked },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "8" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-2 my-2 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("BOUNCES/CLIQUE")]
                              ),
                            ]
                          ),
                          _c(
                            "section",
                            [
                              !_vm.bouncesAndClicksChartDataLoading
                                ? _c("BarChart", {
                                    attrs: {
                                      "chart-data":
                                        _vm.bouncesAndClicksChartDataInformation,
                                      "chart-options":
                                        _vm.bouncesAndClicksChartOptions,
                                    },
                                  })
                                : _c(
                                    "aside",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center",
                                      staticStyle: { height: "320px" },
                                    },
                                    [_c("z-loading")],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-2 my-2 py-2 px-5",
                          attrs: { elevation: "2", height: "420", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("CANAL")]
                              ),
                              _vm.filter.channel
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "#004C91" },
                                      on: { click: _vm.doughnutChartCancel },
                                    },
                                    [_vm._v("$settings_backup_restore")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.totalByChannel
                            ? _c("DoughnutChart", {
                                attrs: {
                                  labels: ["Email", "SMS"],
                                  totals: _vm.totalByChannel,
                                  "chart-info-prop": _vm.donutChartData,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-2 my-2 py-2 px-5",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("USUÁRIOS")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-5 py-4 d-flex justify-end align-center",
                                },
                                [
                                  _c("z-btn", {
                                    attrs: {
                                      small: "",
                                      rounded: "",
                                      primary: "",
                                      text: "",
                                      icon: `mdi-cloud-download`,
                                      "is-loading": _vm.loadingDownloadUsers,
                                    },
                                    on: { click: _vm.downloadDocument },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("z-pagination", {
                            attrs: {
                              "auto-pagination": false,
                              "icon-previous": "mdi-chevron-left",
                              "icon-next": "mdi-chevron-right",
                              "items-per-page": 10,
                              "current-page": _vm.usersTableFilter.page,
                              "number-of-pages":
                                _vm.usersTableFilter.totalPages,
                              items: _vm.usersList,
                            },
                            on: {
                              "click:next": _vm.goNextPageUsersList,
                              "click:previous": _vm.backPreviousPageUsersList,
                              "change:page": function ($event) {
                                return _vm.gotToChoosenPage($event, "user")
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "data",
                                  fn: function ({ paginatedItems }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "table-wrapper",
                                          staticStyle: {
                                            height: "550px !important",
                                            "max-height": "550px !important",
                                          },
                                        },
                                        [
                                          _c("z-table", {
                                            staticClass: "table",
                                            attrs: {
                                              headers: _vm.headers,
                                              items: paginatedItems,
                                              "icon-next": "mdi-chevron-right",
                                              "icon-previous":
                                                "mdi-chevron-left",
                                              "is-loading":
                                                _vm.loadingUserListTable,
                                            },
                                            on: {
                                              sort: function ($event) {
                                                return _vm.getSortedUsersList(
                                                  $event
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "no-data",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "no-data-table d-flex align-end justify-center",
                                                        },
                                                        [
                                                          _c("h3", [
                                                            _vm._v(
                                                              "Sem dados disponíveis."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: `item.created`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.formatDate(
                                                                  item.created
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: `item.journeyStepId`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("div", [
                                                        _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getStepIdName(
                                                                  item.journeyStepId
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1497069590
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-2 my-2 py-2 px-5",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-2 mt-2 d-flex align-center justify-space-between",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "0.8rem",
                                    "font-weight": "600",
                                  },
                                },
                                [_vm._v("TEMPLATES")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-5 py-4 d-flex justify-end align-center",
                                },
                                [
                                  _c("z-btn", {
                                    attrs: {
                                      small: "",
                                      rounded: "",
                                      primary: "",
                                      text: "",
                                      icon: `mdi-cloud-download`,
                                      "is-loading":
                                        _vm.loadingDownloadTemplates,
                                    },
                                    on: {
                                      click: _vm.downloadDocumentTemplates,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("z-pagination", {
                            attrs: {
                              "auto-pagination": false,
                              "icon-previous": "mdi-chevron-left",
                              "icon-next": "mdi-chevron-right",
                              "items-per-page": 10,
                              items: _vm.templates,
                              "current-page": _vm.templateTableFilter.page,
                              "number-of-pages":
                                _vm.templateTableFilter.totalPages,
                            },
                            on: {
                              "click:next":
                                _vm.goNextPageTemplatesReportsByCompanyId,
                              "click:previous":
                                _vm.backPreviousPageTemplatesReportsByCompanyId,
                              "change:page": function ($event) {
                                return _vm.gotToChoosenPage($event, "template")
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "data",
                                  fn: function ({ paginatedItems }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "table-wrapper",
                                          staticStyle: {
                                            height: "550px !important",
                                            "max-height": "550px !important",
                                          },
                                        },
                                        [
                                          _c("z-table", {
                                            staticClass: "table",
                                            attrs: {
                                              headers: _vm.headersTemplates,
                                              items: paginatedItems,
                                              "icon-next": "mdi-chevron-right",
                                              "icon-previous":
                                                "mdi-chevron-left",
                                              "is-loading":
                                                _vm.loadingSortTableTemplate,
                                            },
                                            on: {
                                              sort: function ($event) {
                                                return _vm.getSortedTemplateReportsByCompanyId(
                                                  $event
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "no-data",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "no-data-table d-flex align-end justify-center",
                                                        },
                                                        [
                                                          _c("h3", [
                                                            _vm._v(
                                                              "Sem dados disponíveis."
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: `item.openRate`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.type ===
                                                              "email"
                                                              ? item.openRate.toFixed(
                                                                  2
                                                                ) + "%" || "0%"
                                                              : "-"
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: `item.totalSent`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.totalSent ||
                                                              "0"
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: `item.clickRate`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.clickRate.toFixed(
                                                              2
                                                            ) || "0"
                                                          ) + "%"
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: `item.bounceRate`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.type ===
                                                              "email"
                                                              ? item.bounceRate.toFixed(
                                                                  2
                                                                ) + "%" || "0%"
                                                              : "-"
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: `item.spamRate`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.type ===
                                                              "email"
                                                              ? item.spamRate.toFixed(
                                                                  2
                                                                ) + "%" || "0"
                                                              : "-"
                                                          ) + " "
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: `item.optOutRate`,
                                                  fn: function ({ item }) {
                                                    return [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.type ===
                                                              "email"
                                                              ? item.optOutRate.toFixed(
                                                                  2
                                                                ) + "%" || "0"
                                                              : "-"
                                                          ) + " "
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3998109079
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dontHaveAllDatasToCards,
                  expression: "dontHaveAllDatasToCards",
                },
              ],
              staticClass: "wrapper__card__overlay",
            },
            [_vm._m(0)]
          ),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "wrapper__card" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [_c("z-loading")],
                1
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "message" }, [
      _c("span", [
        _vm._v(
          " Não foram encontrados resultados com esses filtros. Tente refazer sua pesquisa. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }